.h-container{
    gap:5rem;
    padding-bottom: 1rem;
    color: var(--secondary);
  }
.h-menu{
    gap: 1rem;
}

@media(max-width:768px){
  
    .h-menu{
        display: none;
        visibility: hidden;
    }
} 