.c-container{
    gap: 0.5rem ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactModes{
    gap: 1rem;
    top: 1.5rem;
}
.contactModes .row{
    gap: 1.5rem;
}
.mode{
    width: 16rem;
    padding: 1rem;
    border: 0.8px solid rgba(128, 128, 128,0.143);
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
}
.mode .button{
    width: 100%;
    background: var(--lightBlue);
    color: var(--blue);
    font-size: 0.9rem;
    font-weight: 600;
}
.mode>:nth-child(1)
{
    width: 100%;
    gap: 1.6rem;
}
.mode .detail .primaryText{
    font-size: 1.1rem;
    font-weight: 600;
}
.mode:hover{
    scale: 1.1;
    box-shadow: var(--shadow);
}
.mode .button:hover{
    background: var(--blue-gradient);
    color: #fff;
    scale: 0.8;
}
/* .image-container{
    height: 25rem;
    width: 20rem;
}
.image-container>img{
    width: 100%;
    height: 100%;
} */
@media(max-width:640px){
    .flexCenter {
        display: flex;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      .flexStart {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
      }
    .contactModes{
        position: relative;

        margin: auto;
    }
}